<template>
  <div id="canvas"></div>
</template>

<script>
import * as three from 'three'
// import {TrackballControls} from 'three/examples/jsm/controls/TrackballControls'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'

export default {
  name: 'example02',
  props: {},
  data () {
    return {
      renderer: new three.WebGLRenderer(),
      scene: new three.Scene(),
      camera: new three.PerspectiveCamera(75,window.innerWidth/window.innerHeight, 0.1, 1000),
      // OrthographicCamera(
      //   100*window.innerWidth/window.innerHeight/-2,
      //   100*window.innerWidth/window.innerHeight/2,
      //   100/2,
      //   100/-2,
      //   0.1,
      //   1000
      // ),//
      material: new three.MeshBasicMaterial({color: 0xffff00}),
      lineBasicMaterial: new three.LineBasicMaterial({color: 0xff0000}),
      controls: null,
      clock: new three.Clock()
    };
  },
  computed: {
    cube() {
      const boxGeometry = new three.BoxGeometry()
      const box = new three.Mesh(boxGeometry, this.material)
      box.position.set(0,3,0)
      box.castShadow = true
      return box
    },
    line() {
      const points = []
      points.push( new three.Vector3(-10,0,0))
      points.push( new three.Vector3(0,10,0))
      points.push( new three.Vector3(10,0,0))

      const lineGeometry = new three.BufferGeometry().setFromPoints(points)

      return new three.Line(lineGeometry, this.lineBasicMaterial)
    },
    grid () {//xz
      const grid = new three.GridHelper(100,50)
      return grid
    },
    light() {
      const spotLight = new three.SpotLight(0xFFFFFF)
      spotLight.position.set(0,10,10)

      spotLight.castShadow = true
      spotLight.shadow.mapSize = new three.Vector2(1024,1024)
      return spotLight
    },
    plane() {
      const planeGeometry = new three.PlaneGeometry(80,80)
      const planeMaterial = new three.MeshLambertMaterial({color:0xffffff})

      const plane = new three.Mesh(planeGeometry, planeMaterial)
      plane.rotation.x = -Math.PI / 2
      plane.receiveShadow = true
      return plane

    }
  },
  created () {},
  mounted() {
    this.init()

    // this.controls = new TrackballControls(this.camera, this.renderer.domElement)
    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.controls.enablePan = false
    // this.controls.enableZoom = false

    this.addObject()

    this.animate()
  },
  methods: {

    init() {
      this.renderer.setClearColor(new three.Color(0x0f6f0f))
      this.renderer.setSize(window.innerWidth, window.innerHeight)
      this.renderer.shadowMap.enabled = true
      
      document.querySelector('#canvas').appendChild(this.renderer.domElement)

      this.camera.position.set(0,4,10)
      this.camera.lookAt(0,3,0)
    },

    addObject() {
      this.scene.add(this.cube)

      this.scene.add(this.line)

      this.scene.add(this.grid)

      this.scene.add(this.light)

      this.scene.add(this.plane)
    },

    animate () {

      this.cube.rotation.x += 0.01
      this.cube.rotation.y += 0.01

      this.controls.update(this.clock.getDelta())
      this.renderer.render(this.scene, this.camera)
      requestAnimationFrame(this.animate)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
